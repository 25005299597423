/*******************************
         Site Overrides
*******************************/

/*
	This overrides styles set in "semantic/src/definitions/collections/menu.less".
*/

/*
	This override changes the menu link appear and behavior,
	including hover and active states.
*/

.ui.menu a {
	color: @sitkaGreyLight;
}

.ui.secondary.menu .item {
	transition: all 250ms ease-in;
	letter-spacing: 0.05px;
}

.ui.secondary.menu .active.item, .ui.secondary.menu .active.item:hover, .ui.secondary.menu .item:hover {
	background: none;
	a {
		color: darken(@sitkaGreyLight, 10);
	}
}

.ui.secondary.menu .active.item {
	font-weight: bold;
  	letter-spacing: 0;
}