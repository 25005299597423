/*******************************
         Site Overrides
*******************************/

.ui.sortable.table thead th {
    border-left: 0 !important;
}

.ui.sortable.table thead th.sorted {
    background: inherit !important;
}

.ui.sortable.table thead th:hover {
    background: inherit !important;
}