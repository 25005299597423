/*******************************
         Site Overrides
*******************************/

.ui.toggle.checkbox label::before, .ui.toggle.checkbox label:hover::before, .ui.toggle.checkbox label::after, .ui.toggle.checkbox label:hover::after {
  border: 1px solid #d4d4d4;
}

.ui.toggle.checkbox input:checked ~ label::before, .ui.toggle.checkbox input:checked ~ label:hover::before, .ui.toggle.checkbox input:checked ~ label::after, .ui.toggle.checkbox input:checked ~ label:hover::after {
  border: 1px solid darken(@green, 10);
}

.ui.toggle.checkbox label::before {
  color: #7b7b7b;
  content: "Off";
  font-size: 12px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 8px;
  padding-top: 2.5px;
  text-align: right;
  transition: background-color ease-in 0.2s;
}

.ui.toggle.checkbox input:checked ~  label::before {
  content: "On";
  color: white;
  padding-left: 0.6em;
  padding-right: 0;
  text-align: left;
}