/*******************************
        User Overrides
*******************************/

&.ui.selection.dropdown {
  min-height: 1em;
  height: auto;
}

&.ui.selection.dropdown>.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

&.ui.dropdown .menu > .item {
  line-height: 1.929em;
}
