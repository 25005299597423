/*******************************
         Site Overrides
*******************************/

.ui.search {
    border-radius: 4px;
}

.ui.search.fluid .input {
    width: 100%;
}