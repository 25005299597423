/*******************************
         Site Overrides
*******************************/

/*
	This overrides styles set in "semantic/src/definitions/elements/button.less".
*/

.ui.button {
	border-radius: 4px;
	border: none;
	font-size: @18px;
	font-weight: @fontWeightBold;
}

.ui.button.white {
	background-color: @white;
}

.ui.button.text-button {
    background: unset;
	text-align: left;
	margin: 0 0;
    padding: 0 0;

}

.ui.button.text-button.blue {
	color: @blue;

	&:hover, &:focus, &:active {
		background-color: unset;
		color: darken(@sitkaPrimary, 10);
	}
}

.ui.button.icon {
	padding: 0;
	background-color: transparent;
}

.ui.button.icon.inverted:hover {
	background-color: unset;
	color: unset;
}

.ui.button.inverted, .ui.button.icon.inverted:hover {
	box-shadow: none !important;
}